
import { Component, Vue } from 'vue-property-decorator';
import LibraryApi from '@/module/api/LibraryApi';
import LibraryInfo from '@/model/library/LibraryInfo';
import FileId from '@/model/type/FileId';
import FileUpload from '@/components/Parts/FileUpload.vue';
import Category, { ENGINNER } from '@/model/library/Category';
import FileInfo from '@/model/type/FileInfo';
import Overview from '@/model/library/Overview';
import TypeDateTime from '@/model/type/TypeDateTime';
import UserID from '@/model/user/UserID';
import UserName from '@/model/user/UserName';
import UploadFileContainer from '@/model/type/UploadFileContainer';
import BackButton from '@/components/Parts/BackButton.vue';

@Component({
  components: {
    FileUpload,
    BackButton
  }
})
export default class LibraryUpload extends Vue {
  category:Category = new Category();
  libraryInfo:LibraryInfo = new LibraryInfo(new FileInfo(), this.category, new Overview(''), new TypeDateTime(), new TypeDateTime(), new UserID(), new UserName());
  fileId:FileId = new FileId();
  uploadfile:UploadFileContainer = new UploadFileContainer();
  edit:boolean = false;
  data = new FormData();
  isRunning = false;
  fileChanging = false;

  mounted():void{
    if (this.$route.params.fileId !== undefined) {
      LibraryApi.findById(this.$route.params.fileId).then((res) => {
        this.libraryInfo = res.data;
      });
      this.edit = true;
    }
    if (this.$route.params.category !== undefined) {
      this.category.value = this.$route.params.category;
      this.category.getDispString();
    }
  }
  save():void{
    if (this.showFileSelector && this.uploadfile.file == null) {
      this.$root.$emit('event-show-snackbar-infinity', 'ファイルを選択してください。');
      return;
    }
    this.isRunning = true;
    this.data.append('category', this.libraryInfo.category.value);
    this.data.append('overview', this.libraryInfo.overview.value,);
    this.data.append('fileId', this.libraryInfo.fileInfo.id.value);
    this.data.append('fileName', this.libraryInfo.fileInfo.name.value);
    if (this.uploadfile.file) {
      this.data.append('upfile', this.uploadfile.file);
    }
    this.saveMain(this.data);
  }
  saveMain(data:FormData):void{
    LibraryApi.save(data).then(() => {
      this.$root.$emit('event-show-snackbar', '登録完了');
      this.data = new FormData();
      this.isRunning = false;
      this.$router.go(-1);
    });
  }

  /**
   * ファイル選択の表示
   */
  get showFileSelector():boolean {
    if (this.fileChanging) return true;
    if (!this.libraryInfo.fileInfo.id.value) return true;
    return false;
  }

  update():void{
    if (this.uploadfile.file !== null) {
      this.data.append('deleteFileId', this.libraryInfo.fileInfo.id.value);
    }
    this.save();
  }
  del():void{
    this.isRunning = true;
    LibraryApi.delete(this.libraryInfo).then(() => {
      this.$root.$emit('event-show-snackbar', '削除完了');
      this.isRunning = false;
      this.$router.go(-1);
    });
  }
}
