import FileInfo from '../type/FileInfo';
import TypeDateTime from '../type/TypeDateTime';
import UserID from '../user/UserID';
import UserName from '../user/UserName';
import Category from './Category';
import Overview from './Overview';

export default class LibraryInfo {
  fileInfo: FileInfo;

  category: Category;

  overview: Overview;

  createDate: TypeDateTime;

  updateDate: TypeDateTime;

  userId: UserID;

  userName: UserName;

  constructor(
    fileInfo: FileInfo,

    category: Category,

    overview: Overview,

    createDate: TypeDateTime,

    updateDate: TypeDateTime,

    userId: UserID,

    userName: UserName
  ) {
    this.fileInfo = (fileInfo === undefined ? new FileInfo() : fileInfo);

    this.category = (category === undefined ? new Category() : category);

    this.overview = (overview === undefined ? new Overview() : overview);

    this.createDate = (createDate === undefined ? new TypeDateTime() : createDate);

    this.updateDate = (updateDate === undefined ? new TypeDateTime() : updateDate);

    this.userId = (userId === undefined ? new UserID() : userId);

    this.userName = (userName === undefined ? new UserName() : userName);
  }

  public static create() {
    const result:LibraryInfo[] = [];
    return result;
  }
}
