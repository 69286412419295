/**
 * 【Vue専用】アップロードファイル格納クラス
 */
export default class UploadFileContainer {
  /** 連番-v-for制御用 */
  seq: number;
  /** ファイル */
  file: File|null;

  constructor(
    seq?: number,
    file?: File
  ) {
    this.seq = (seq === undefined ? -1 : seq);
    this.file = (file === undefined ? null : file);
  }
}
